/* src/components/Games.css */

.machine-list-container {
  padding: 20px;
}

.machine-count {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
}

.machine-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.machine-tile {
  background: #333;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  height: 300px; /* Ensure all tiles have the same height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.machine-tile:hover {
  transform: scale(1.05);
}

.machine-image {
  width: 100%;
  height: 200px; /* Set a fixed height for images */
  object-fit: cover; /* Crop the image to fit the fixed dimensions */
  border-radius: 5px;
  margin-bottom: 10px;
}

.machine-info {
  font-size: 0.9em;
}

@media (max-width: 600px) {
  .machine-tile {
    padding: 5px;
    height: 250px; /* Adjust height for smaller screens */
  }

  .machine-count {
    font-size: 1.2em;
  }

  .machine-image {
    height: 150px; /* Adjust image height for smaller screens */
  }
}
