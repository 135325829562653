/* src/components/Header.css */

body {
  padding-top: 56px; /* Adjust according to your navbar height */
}

.navbar-brand, .nav-link {
  color: #ffeb3b !important;
}

.navbar-brand:hover, .nav-link:hover {
  color: #fff !important;
}
