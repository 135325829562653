.membership-container {
  text-align: center;
  padding: 50px;
  max-width: 800px;
  margin: 0 auto;
}

.membership-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.membership-container p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.stripe-button {
  background-color: #6772e5;
  color: white;
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.stripe-button:hover {
  background-color: #5469d4;
}