

/* src/App.css */

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  padding-top: 100px; /* Adjust according to your navbar height */
}

.content {
  padding-top: 100px; /* Additional padding to ensure content is not covered by navbar */
}

.navbar-brand, .nav-link {
  color: #ffeb3b !important;
}

.navbar-brand:hover, .nav-link:hover {
  color: #fff !important;
}


body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}


body {
  font-family: Arial, sans-serif;
  background-color: #1a1a1a;
  color: #f0f0f0;
  margin: 0;
  padding: 0;
}
.App {
  text-align: center;
  padding-top: 70px;
}
.section {
  padding: 20px;
}
.contact-form input,
.contact-form textarea,
.contact-form button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #555;
  border-radius: 5px;
  background-color: #333;
  color: #f0f0f0;
}
.contact-form button {
  background-color: #f0c040;
  border: none;
  cursor: pointer;
}
.contact-form button:hover {
  background-color: #d4a520;
}
.machine-list-container {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto;
  width: 80%;
}
.machine-count {
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 0.5em;
}
#machine-list {
  font-weight: 400;
  list-style-type: none;
  padding: 0;
}
#machine-list li {
  margin: 5px 0;
}
#source {
  color: #777;
  font-size: 0.8em;
  margin-top: 10px;
}
.footer {
  background-color: #333;
  padding: 20px;
  text-align: center;
  font-size: 0.9em;
  color: #777;
}



.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.gallery img {
  max-width: 200px;
  border-radius: 10px;
  transition: transform 0.2s;
}

.gallery img:hover {
  transform: scale(1.05);
}


/* start for gamelist */
/* Add this to App.css */
/* Add this to App.css */

.machine-list-container {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto;
  width: 80%;
}

.machine-count {
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.machine-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.machine-tile {
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding: 10px;
  color: #f0f0f0;
}

.machine-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.no-image {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #444;
  color: #777;
}

.machine-info {
  margin-top: 10px;
}

#source {
  color: #777;
  font-size: 0.8em;
  margin-top: 10px;
}



/* end for gamelist */

/* src/App.css */
