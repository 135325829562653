/* src/components/Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 10px;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px; /* Adjust the max width */
  position: relative;
  max-height: 90vh; /* Ensure modal does not exceed viewport height */
  overflow-y: auto; /* Enable scrolling for modal content if necessary */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: auto; /* Ensure the modal is centered */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5em;
  cursor: pointer;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.modal-info {
  margin-top: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.modal-info h2 {
  margin-top: 0;
}

.modal-info a {
  color: #add8e6;
}

.additional-links {
  margin-top: 20px;
}

.additional-links h3 {
  margin-bottom: 10px;
}

.additional-links a {
  display: block;
  color: #add8e6;
  margin-top: 5px;
  font-size: 0.9em; /* Adjust the font size for smaller text */
  word-break: break-all; /* Ensure long words break to the next line */
}

.pintips {
  margin-top: 20px;
}

.pintips h3 {
  margin-bottom: 10px;
}

.pintips-content {
  max-height: 200px; /* Limit height of tips container */
  overflow-y: auto; /* Enable vertical scrolling */
}

.pintips ul {
  list-style: none;
  padding-left: 0;
}

.pintips li {
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pintips li p {
  margin: 0;
  padding: 0;
  flex: 1;
}

.pintips li strong {
  margin-left: 10px;
}

.show-more {
  text-align: center;
  cursor: pointer;
  color: #add8e6;
  margin-top: 10px;
}
