/* src/components/Alerts.css */

.alerts-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.alerts-container h1 {
  color: #f1f1f1; /* Lighten the header color */
}

.alerts-container p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #cccccc; /* Light gray for the paragraph text */
}

#signupForm label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #ffffff; /* Make labels white for better contrast */
}

#signupForm input[type="text"],
#signupForm input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #333; /* Darker background for inputs */
  color: #ffffff; /* White text for inputs */
}

.smsOptInLabel {
  display: flex;
  align-items: center;
  margin: 10px 0;
  color: #ffffff; /* White text for the checkbox label */
}

.smsOptInLabel input[type="checkbox"] {
  margin-right: 10px;
}

.required {
  color: #ff5555; /* Bright red for the asterisks */
  margin-left: 5px;
}

.required-note {
  color: #ff5555; /* Bright red for the required note */
  font-size: 14px;
  margin-top: 10px;
}

#signupForm button {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #28a745;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

#signupForm button:hover {
  background-color: #218838;
}

.error {
  color: #ff5555; /* Bright red for error messages */
  font-size: 14px;
  margin-top: -5px;
}
